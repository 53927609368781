import React from 'react';
import PropTypes from 'prop-types';
import ResultCount from 'components/form-wizards/click-portal-search/ResultCount';
import ToolTip from 'components/base/toolTip/ToolTip';
import ResultCard from './result-card/ResultCard';

function ClickPortalResultsSection({
  results,
  resultType = 'matches',
  disclosure,
  isLoading,
  title,
}) {
  function getBadge(index) {
    return resultType === 'matches' && index === 0 ? "Editor's Choice" : '';
  }

  return (
    <div
      className={`clickPortalResults__section clickPortalResults__section--${resultType}`}
    >
      {title && (
        <h4
          className="clickPortalResults__sectionTitle"
          data-testid={`clickPortalResults__sectionTitle--${resultType}`}
        >
          {title}
        </h4>
      )}
      <div className="clickPortalResults__meta">
        <div
          className="clickPortalResults__count"
          data-testid={`clickPortalResults__count--${resultType}`}
        >
          <ResultCount count={results?.length} />
        </div>
        {disclosure && (
          <div className="clickPortalResults__disclosure">
            <ToolTip label="Advertising Disclosure" positionOnMobile>
              {disclosure}
            </ToolTip>
          </div>
        )}
      </div>
      <ul
        className="clickPortalResults clickPortalResults--nonMatches"
        data-testid={`clickPortalResults--${resultType}`}
      >
        {results.map((school, index) => {
          return (
            <li
              className="clickPortalResults__item"
              key={`${school.id}_${resultType}`}
            >
              <ResultCard
                isLoading={isLoading}
                key={school.id}
                schoolInfo={school}
                index={index}
                badge={getBadge(index)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

ClickPortalResultsSection.propTypes = {
  results: PropTypes.array,
  resultType: PropTypes.string,
  disclosure: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default ClickPortalResultsSection;

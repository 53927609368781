import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import GlobalContext from 'hooks/contexts/GlobalContext';
import cx from 'utils/classnames';
import getFormSelectionObjects from './utils/getFormSelectionObjects';

function ClickPortalSearchHeader({ title, subTitle, showTitle }) {
  const {
    microSiteTaxonomyMap,
    clickPortal: { currentSelection, isLoading, filterKeyDerivedValues },
  } = useContext(GlobalContext);

  const { degree, category } = getFormSelectionObjects(
    currentSelection,
    microSiteTaxonomyMap
  );

  const hasSelection =
    category && degree && !filterKeyDerivedValues?.hasDerivedValues;

  const _title =
    !hasSelection || isLoading
      ? title
      : `${get(degree, 'label')}s in ${get(category, 'label')}`;

  const _subTitle =
    !hasSelection || isLoading
      ? subTitle
      : 'Our Picks for Online Colleges with';

  if (!showTitle) {
    return null;
  }
  return (
    <div
      className={cx('clickPortalSearchHeader', {
        'clickPortalSearchHeader--isLoading': isLoading,
      })}
    >
      {showTitle && (
        <h1 className="clickPortalSearchHeader__title">
          <span className="clickPortalSearchHeader__preamble">{_subTitle}</span>
          <span className="clickPortalSearchHeader__titleText">{_title}</span>
        </h1>
      )}
    </div>
  );
}

ClickPortalSearchHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showTitle: PropTypes.bool,
};

export default ClickPortalSearchHeader;
